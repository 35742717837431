<template>
  <div class="content align">
    <div class="di" :class="dongxiao == 1 ? 'fade-in-bottom' : ''"></div>
    <h2 :class="dongxiao == 1 ? 'fade-bottom' : ''">
      方案中心
      <div class="jieshao">为经济社会持续发展提供信息化服务</div>
    </h2>
    <div class="center" :class="dongxiao == 1 ? 'fade-bottom-1' : ''">
      <img class="img" src="../../../assets/imgs/home/fanan.png" alt />
      <div class="liaojie" @click="goto">
        了解更多
        <!-- <div class="img"></div> -->
        <i class="el-icon-right"></i>
      </div>
      <div class="zuoyi">
        <div class="title">智慧水务解决方案</div>
        <div class="son">
          智慧防汛方案
          <em></em>
        </div>
        <div class="son">
          智慧供水方案
          <em></em>
        </div>
        <div class="son">
          智慧排水方案
          <em></em>
        </div>
        <div class="son">
          智慧河湖方案
          <em></em>
        </div>
      </div>
      <div class="zuoer">
        <div class="title">城市安全解决方案</div>
        <div class="son">
          地质灾害监测预警
          <em></em>
        </div>
        <div class="son">
          道桥健康监测预警
          <em></em>
        </div>
        <div class="son">
          水、土气监测预警
          <em></em>
        </div>
        <div class="son">
          城市管网监测预警
          <em></em>
        </div>
        <div class="son">
          应急管理决策支持
          <em></em>
        </div>
      </div>
      <div class="youyi">
        <div class="title">智慧城市解决方案</div>
        <div class="son">
          城市大脑方案
          <em></em>
        </div>
        <div class="son">
          智慧交通方案
          <em></em>
        </div>
        <div class="son">
          智慧文旅方案
          <em></em>
        </div>
        <div class="son">
          智慧环保方案
          <em></em>
        </div>
        <div class="son">
          智慧教育方案
          <em></em>
        </div>
        <div class="son">
          智慧园区方案
          <em></em>
        </div>
      </div>
      <div class="youer">
        <div class="title">智慧政务解决方案</div>
        <div class="son">
          电子证照系统
          <em></em>
        </div>
        <div class="son">
          政务服务系统
          <em></em>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dongxiao"],
  methods: {
    goto() {
      this.$router.push("/solutionCenter");
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 1920px;
  height: 100%;
  background-color: rgba(29, 29, 29, 1);
  margin: 0 auto;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  padding: 130px 260px;
  padding-top: 104px;
  position: relative;
  .di {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(21, 21, 21, 1);
    //background-color: pink;
  }

  .center {
    width: 646px;
    height: 526px;
    position: relative;
    left: 370px;

    top: 54px;
    .img {
      width: 100%;
      height: 100%;
    }
    .liaojie {
      font-family: Alibaba PuHuiTi Xi !important;
      width: 128px;
      height: 44px;
      // position: absolute;
      border: 1px solid rgba(255, 255, 255, 1);
      margin: 66px auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      // text-indent: 26px;

      font-size: 12px;
      cursor: pointer;
      position: relative;
      bottom: 5px;
      opacity: 0.4;
      // .img {
      //   width: 14px;
      //   height: 10px;
      //   background-image: url("../../../assets/imgs/home/jiantou (2).png");
      //   background-size: 100% 100%;
      //   margin-left: 25px;
      // }
    }
    .liaojie:hover {
      // border: 1px solid rgba(68, 112, 255, 1);
      // color: rgba(68, 112, 255, 1);
      opacity: 1;
    }
    // .liaojie:hover .img {
    //   background-image: url("../../../assets/imgs/home/jiantou (1).png");
    //   background-size: 100% 100%;
    // }
    .zuoyi {
      position: absolute;
      top: 26px;
      left: -240px;
      .title {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 22px;
      }

      .son {
        font-size: 22px;
        color: rgba(185, 185, 185, 1);
        font-family: Alibaba PuHuiTi Xi !important;
        margin-top: 6px;
        position: relative;
        em {
          box-sizing: border-box;
          position: absolute;
          top: 8px;
          left: -14px;
          width: 8px;
          height: 8px;
          background-color: rgba(62, 106, 245, 1);
          border-radius: 4px;
          border: 1px solid #fff;
        }
      }
    }
    .zuoyi:hover .title {
      color: rgba(68, 112, 255, 1);
    }
    .zuoer {
      position: absolute;
      top: 331px;
      left: -240px;
      .title {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 22px;
      }
      .son {
        font-size: 22px;
        color: rgba(185, 185, 185, 1);
        font-family: Alibaba PuHuiTi Xi !important;
        margin-top: 6px;
        position: relative;
        em {
          box-sizing: border-box;
          position: absolute;
          top: 8px;
          left: -14px;
          width: 8px;
          height: 8px;
          background-color: rgba(62, 106, 245, 1);
          border-radius: 4px;
          border: 1px solid #fff;
        }
      }
    }
    .zuoer:hover .title {
      color: rgba(68, 112, 255, 1);
    }
    .youyi {
      position: absolute;
      top: 26px;
      right: -240px;
      .title {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 22px;
      }
      .son {
        font-size: 22px;
        color: rgba(185, 185, 185, 1);
        margin-top: 6px;
        position: relative;
        font-family: Alibaba PuHuiTi Xi !important;
        em {
          box-sizing: border-box;
          position: absolute;
          top: 8px;
          left: -14px;
          width: 8px;
          height: 8px;
          background-color: rgba(62, 106, 245, 1);
          border-radius: 4px;
          border: 1px solid #fff;
        }
      }
    }
    .youyi:hover .title {
      color: rgba(68, 112, 255, 1);
    }
    .youer {
      position: absolute;
      top: 331px;
      right: -240px;
      .title {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 22px;
      }
      .son {
        font-size: 22px;
        color: rgba(185, 185, 185, 1);
        margin-top: 6px;
        position: relative;
        font-family: Alibaba PuHuiTi Xi !important;
        em {
          position: absolute;
          box-sizing: border-box;
          top: 8px;
          left: -14px;
          width: 8px;
          height: 8px;
          background-color: rgba(62, 106, 245, 1);
          border-radius: 4px;
          border: 1px solid #fff;
        }
      }
    }
    .youer:hover .title {
      color: rgba(68, 112, 255, 1);
    }
  }
}
</style>
