<template>
  <div class="content">
    <div class="di" :class="dongxiao == 2 ? 'fade-in-bottom' : ''"></div>
    <h2 class="titlee" :class="dongxiao == 2 ? 'fade-bottom' : ''">
      产品中心
      <div class="jieshao">坚持科技创新，构建 万物互联的安全世界</div>
    </h2>
    <div class="nav" :class="dongxiao == 2 ? 'fade-bottom-1' : ''">
      <div
        class="son"
        v-for="(item, index) in list"
        :key="index"
        @click="active(item)"
        :class="item.type == type ? 'active' : ''"
      >
        <div class="img">
          <img :src="item.val ? item.src : item.active" alt />
        </div>
        <div class="title" :class="item.type == type ? 'active' : ''">
          {{ item.title }}
        </div>
        <em v-show="item.type == type"></em>
      </div>
    </div>

    <VueSlickCarousel
      v-bind="set1"
      ref="carousel"
      :class="dongxiao == 2 ? 'fade-bottom-2' : ''"
    >
      <div class="card" v-for="(item, index) in imglist" :key="index">
        <img class="img" :src="item.src" alt="" />

        <div class="mode" v-show="type == item.type ? false : true">
          {{ item.title }}
        </div>
        <div class="meng" v-show="type == item.type">
          <div class="title">
            {{ item.title }}
            <div class="em"></div>
          </div>
          <div class="text">{{ item.text }}</div>
          <div class="box">
            <div class="son" v-for="(v, i) in item.list" :key="i">
              <div class="em"></div>
              {{ v.name }}
            </div>
          </div>
          <div class="liaojie" @click="goto(item)">
            了解更多
            <i class="el-icon-right"></i>
          </div>
        </div>
      </div>
    </VueSlickCarousel>

    <div class="index-left"></div>
    <div class="index-right"></div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  props: ["dongxiao"],

  data() {
    return {
      list: [
        {
          val: true,
          src: require("../../../assets/imgs/home/jianjie (5).png"),
          active: require("../../../assets/imgs/home/big (4).png"),
          title: "智慧水务产品",
          type: 0,
        },
        {
          val: true,
          src: require("../../../assets/imgs/home/jianjie (4).png"),
          active: require("../../../assets/imgs/home/big (1).png"),
          title: "城市安全产品",
          type: 1,
        },
        {
          val: true,
          src: require("../../../assets/imgs/home/jianjie (1).png"),
          active: require("../../../assets/imgs/home/big (2).png"),
          title: "智慧城市产品",
          type: 2,
        },
        {
          val: true,
          src: require("../../../assets/imgs/home/jianjie (2).png"),
          active: require("../../../assets/imgs/home/big (5).png"),
          title: "智能设备产品",
          type: 3,
        },
        {
          val: true,
          src: require("../../../assets/imgs/home/jianjie (3).png"),
          active: require("../../../assets/imgs/home/big (3).png"),
          title: "智慧工具产品",
          type: 4,
        },
      ],
      type: 0,
      imglist: [
        {
          src: require("../../../assets/imgs/home/beijingtu (2).png"),
          type: 0,
          title: "智慧水务产品",
          text: "综合利用物联网、云计算、大数据、人工智能等先进技术，提升水利四预保障能力，加快推进水利现代化",
          list: [
            { name: "数据管理" },
            { name: "平台服务" },
            { name: "数据分析/计算" },
            { name: "数据储存/整合" },
            { name: "平台管理" },
            { name: "安全管理" },
            { name: "广域分级协作" },
          ],
          path: "/shuiwu",
        },
        {
          src: require("../../../assets/imgs/home/beijingtu (1).png"),
          type: 1,
          title: "城市安全产品",
          text: "构建云+端的公共安全立体防控体系，实时动态监测预警、智慧化应用场景、响应应急处置",
          list: [
            { name: "服务管理" },
            { name: "业态分析" },
            { name: "配置管理" },
            { name: "设备管理" },
            { name: "融合采集" },
            { name: "共享权属" },
            { name: "边缘计算" },
          ],
          path: "/chengshianquan",
        },
        {
          src: require("../../../assets/imgs/home/beijingtu (3).png"),
          type: 2,
          title: "智慧城市产品",
          text: "利用汉康智慧城市操作系统促进城市的资源优化配置、社会管理精细有序、城市高效运行和可持续发展",
          list: [
            { name: "基础信息在线加载" },
            { name: "GIS+BIM+IOT+AI" },
            { name: "城市规划“一张图”" },
            { name: "城市难题“一眼明”" },
            { name: "城市治理“一盘棋”" },
          ],
          path: "/zhihui",
        },
        {
          src: require("../../../assets/imgs/home/beijingtu (4).png"),
          type: 3,
          title: "智能设备产品",
          text: "智能设备为全域物联感知提供支撑",
          list: [
            { name: "智慧操作" },
            { name: "信息展示" },
            { name: "数据分析" },
            { name: "监控预警" },
          ],
          path: "/shebei",
        },
        {
          src: require("../../../assets/imgs/home/beijingtu (5).png"),
          type: 4,
          title: "智慧工具产品",
          text: "智慧工具为城市应用提供数据汇聚、处理、分析等全生命周期保障",
          list: [
            { name: "智慧操作" },
            { name: "信息展示" },
            { name: "数据分析" },
            { name: "监控预警" },
          ],
          path: "/gongju",
        },
      ],
      set: {
        dots: true,
        infinite: true,
        initialSlide: 0, //图片开始的索引
        speed: 500, //动画是速度
        slidesToShow: 3, //一排显示 几个
        slidesToScroll: 1, //显示几排
        swipeToSlide: true,
        centerMode: true,

        //  variableWidth: true,
      },
      set1: {
        dots: true,
        infinite: true,
        centerMode: true,
        centerPadding: "20px",
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        touchMove: false,
      },
    };
  },
  components: {
    VueSlickCarousel,
  },
  methods: {
    active(item) {
      this.type = item.type;
      //  console.log("item :>> ", item);
      this.$refs.carousel.goTo(item.type);
      //this.$refs.carousel.goTo(item.type);
    },
    goto(val) {
      this.$router.push(val.path);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.active {
  // font-weight: 700;
  color: rgba(255, 255, 255, 1);
  font-family: Alibaba PuHuiTi Xi !important;
  opacity: 1 !important;
}
.content {
  width: 1920px;
  // height: 960px;
  height: 100%;
  margin: 0 auto;
  // background: rgba(21, 21, 21, 1);
  padding: 130px 0;
  padding-top: 104px;
  position: relative;

  .di {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(21, 21, 21, 1);
    //background-color: pink;
  }
  .titlee {
    font-size: 40px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    line-height: 58px;
    color: #ffffff;
    // margin-top: 130px;
    margin-left: 260px;
    .jieshao {
      font-size: 18px;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      line-height: 26px;
      color: #a2a2a2;
    }
  }
  .nav {
    margin-top: 66px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #292929;
    font-family: Alibaba PuHuiTi Xi !important;
    // padding-bottom: 21px;
    // height: 125px;
    margin-bottom: 60px;
    .son {
      width: 200px;
      height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      color: rgba(136, 136, 136, 1);
      opacity: 0.5;
      cursor: pointer;
      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
      }
      em {
        position: absolute;
        width: 60%;
        height: 2px;
        background-color: #fff;
        bottom: -2px;
        left: 20%;
      }
      .title {
        margin-top: 8px;
      }
    }
  }

  .card {
    width: 640px;
    height: 380px;

    .img {
      width: 100% !important;
      height: 100%;
    }
    .meng {
      width: 100%;
      padding: 40px;
      position: absolute;
      // width: 700px;
      height: 100%;
      top: 0;
      left: 0px;
      background: rgba(38, 70, 182, 0.6);
      padding: 33px 56px;
      //display: none;

      .title {
        font-size: 22px;
        font-weight: 700;
        position: relative;
        font-family: Alibaba PuHuiTi Xi !important;
        .em {
          width: 30px;
          height: 2px;
          background-color: #fff;
          position: absolute;
          top: 48px;
          left: 0;
        }
      }
      .text {
        margin-top: 42px;
        font-size: 17px;
        font-family: Alibaba PuHuiTi Zx !important;
      }
      .box {
        width: 500px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 26px;
        // justify-content: space-between;
        font-family: Alibaba PuHuiTi Zx !important;
        .son {
          // width: 25%;
          font-size: 17px;
          margin-top: 15px;
          position: relative;
          margin-right: 30px;
          display: flex;
          align-items: center;
          .em {
            width: 6px;
            height: 6px;
            background-color: #fff;
            border-radius: 3px;
            margin-right: 10px;
          }
        }
      }
      .liaojie {
        width: 128px;
        height: 44px;
        // position: absolute;
        border: 1px solid #ffffff;
        margin-top: 80px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        justify-content: space-around;
        // text-indent: 20px;
        font-size: 12px;
        position: absolute;
        bottom: 28px;
        left: 56px;
        // padding: 0 26px;
        cursor: pointer;
        font-family: Alibaba PuHuiTi Zx !important;

        .img {
          width: 14px;
          height: 10px;
          background-image: url("../../../assets/imgs/home/jiantou (2).png");
          background-size: 100% 100%;
          margin-left: 25px;
        }
      }
      .liaojie:hover {
        background-color: #fff;
        color: #2646b6;
      }
    }
  }
  // .card:hover .meng {
  //   display: block;
  // }
  .index-left {
    width: 260px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(270deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
  }
  .index-right {
    width: 260px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
  }
}
</style>
