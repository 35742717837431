<template>
  <div class="index tohome">
    <Header></Header>

    <div class="index-lubo">
      <el-carousel
        direction="vertical"
        :autoplay="false"
        ref="carousel"
        trigger="click"
        :loop="false"
        @change="xxxx"
      >
        <el-carousel-item name="0">
          <div class="banner" :class="dongxiao == 0 ? 'fade-in-bottom' : ''">
            <el-carousel
              class="imgx"
              arrow="never"
              ref="imgx"
              :interval="8000"
              @mouseenter.native="delHandleMouseEnter"
            >
              <el-carousel-item
                v-for="(item, index) in list"
                :key="index"
                class="dd"
              >
                <img :src="item.src" alt />
              </el-carousel-item>
            </el-carousel>

            <div class="index-xinwen-lunbo">
              <div class="lunbo">
                <el-carousel direction="vertical" :interval="4000">
                  <el-carousel-item v-for="(item, index) in bobao" :key="index">
                    <div class="box xi" @click="goto">
                      <img class="img" :src="item.src" alt="" />
                      <div class="title">{{ item.name }}</div>
                      <!-- <div class="time">{{ item.time }}</div> -->
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item name="1">
          <SolutionCenter :dongxiao="dongxiao"></SolutionCenter
        ></el-carousel-item>
        <el-carousel-item name="2">
          <ProductCenter :dongxiao="dongxiao"></ProductCenter
        ></el-carousel-item>
        <el-carousel-item name="3">
          <!-- <ServiceCenter :dongxiao="dongxiao"></ServiceCenter> -->
          <Anli :dongxiao="dongxiao"></Anli>
        </el-carousel-item>
        <!-- <el-carousel-item name="4">
          <News :dongxiao="dongxiao"></News
        ></el-carousel-item> -->
        <el-carousel-item name="4">
          <Icon :bool="show" :ICount="ICount" :dongxiao="dongxiao"></Icon
        ></el-carousel-item>
        <!-- <el-carousel-item name="6"><Anli></Anli> </el-carousel-item> -->
      </el-carousel>
    </div>

    <!-- <Footer v-show="test"></Footer> -->

    <div class="toback" ref="toback">
      <div class="lianxi">
        <div class="ph">
          <div class="son">
            <div class="bar">
              <div class="top">咨询电话</div>
              <div class="bottom">133-3098-1239</div>
            </div>
            <div class="bar">
              <div class="top">技术支持</div>
              <div class="bottom">189-8005-3442</div>
            </div>
            <div class="bar">
              <div class="top">售后电话</div>
              <div class="bottom">400-8878-352</div>
            </div>
          </div>
        </div>
        <div class="wx">
          <div class="son">
            <img src="../../assets/imgs/home/gongzonghao.png" alt />
            <div class="tip">(成都汉康微信)</div>
          </div>
        </div>
      </div>
      <!-- <div class="up" @click="up"></div> -->
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
//import Footer from "../../components/Footer.vue";
//import MarketingCenter from "./components/MarketingCenter.vue";
import ProductCenter from "./components/ProductCenter.vue";
import SolutionCenter from "./components/SolutionCenter.vue";
////import ServiceCenter from "./components/ServiceCenter.vue";
//import News from "./components/News.vue";
//import About from "./components/About.vue";
import Icon from "./components/Icon.vue";
import Anli from "./components/anli.vue";
export default {
  components: {
    Header,
    //Footer,
    // MarketingCenter,
    ProductCenter,
    SolutionCenter,
    ///ServiceCenter,
    //News,
    // About,
    Icon,
    Anli,
  },
  data() {
    return {
      list: [
        {
          src: require("../../assets/imgs/lunbo/11.jpg"),
        },
        {
          src: require("../../assets/imgs/lunbo/22.jpg"),
        },
        {
          src: require("../../assets/imgs/lunbo/33.jpg"),
        },
        {
          src: require("../../assets/imgs/lunbo/44.jpg"),
        },
      ],
      introductoryList: [
        {
          src: require("../../assets/imgs/home/jianjie (4).png"),
          top: "物联网总线平台",
          bottom: "基于I-Ching物联网总线平台",
        },
        {
          src: require("../../assets/imgs/home/jianjie (5).png"),
          top: "大数据平台",
          bottom: "提升数据利用率构建城市数据生态",
        },
        {
          src: require("../../assets/imgs/home/jianjie (1).png"),
          top: "智慧城市操作系统",
          bottom: "大数据信息分析处理系统",
        },
        {
          src: require("../../assets/imgs/home/jianjie (2).png"),
          top: "智慧城市可视化",
          bottom: "基于I-Ching物联网总线平台",
        },
        {
          src: require("../../assets/imgs/home/jianjie (3).png"),
          top: "智能设备采集",
          bottom: "社会持续发展信号化服务",
        },
      ],
      bobao: [
        // {
        //   name: "喜讯：川力汉康直饮水大数据平台正式签约投资",
        //   time: "2022-02-18",
        //   src: require("../../assets/imgs/xinwen/4.jpg"),
        // },
        {
          name: "热烈祝贺成都汉康成立二十周年",
          time: "2022-02-20",
          src: require("../../assets/imgs/xinwen/2 (1).png"),
        },
      ],
      arr: [],
      newarr: [],
      timer: null,

      test: false,

      screenWeight: 0, // 屏幕宽度
      screenHeight: 0, // 屏幕高度
      index: 1, // 用于判断翻页
      curIndex: 1, // 当前页的index
      startTime: 0, // 翻屏起始时间
      endTime: 0, // 上一次翻屏结束时间
      nowTop: 0, // 翻屏后top的位置
      pageNum: 0, // 一共有多少页
      main: Object,
      obj: Object,

      i: 0,
      show: false,

      ICount: 0,

      dongxiao: -1,

      navShow: true,
    };
  },
  methods: {
    up() {
      document.body.scrollTop = 0;
    },

    delHandleMouseEnter() {
      this.$refs.imgx.handleMouseEnter = () => {};
    },

    scroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      this.arr.push(scroll);

      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];
        if (a - b >= 0) {
          // console.log("往下滑");
          if (scroll > 600) {
            this.$refs.toback.style.display = "block";
          }
        } else {
          // console.log("往上滑");

          if (scroll < 600) {
            this.$refs.toback.style.display = "none";
          }
        }
      }
    },
    goto() {
      this.$router.push("/pressCenter");
    },

    scrollFun(item) {
      this.startTime = new Date().getTime();
      if (this.startTime - this.endTime > 100) {
        if (item.wheelDelta >= 0) {
          //  console.log("往上 ");
          this.$refs.carousel.prev();
        } else {
          // console.log("往下");
          this.$refs.carousel.next();

          if (this.i == 4) {
            //显示底部
            this.show = true;
          } else {
            this.show = false;
          }
        }
      }
      this.endTime = new Date().getTime();
      // console.log("  this.endTime jiehsu  :>> ", this.endTime);
    },

    xxxx(now) {
      this.dongxiao = now;
      //   console.log("this.dongxiao :>> ", this.dongxiao);
      if (now == 4) {
        this.i = now;
        this.ICount = now;
        //  console.log("this.ICount :>> ", this.ICount);
      } else {
        this.i = 0;
        this.show = false;
        this.ICount = 0;
      }
    },

    change(val) {
      //console.log("111 :>> ", val);
      this.toPage(val);
    },
  },
  mounted() {
    document.body.scrollTop = 0;
    this.delHandleMouseEnter();
    // let that = this;
    // let arr = [];
    window.addEventListener("scroll", this.scroll, true);

    // 浏览器兼容
    if (navigator.userAgent.toLowerCase().indexOf("firefox") != -1) {
      document.addEventListener("DOMMouseScroll", this.scrollFun, false);
    } else if (document.addEventListener) {
      document.addEventListener("mousewheel", this.scrollFun, false);
    } else if (document.attachEvent) {
      document.attachEvent("onmousewheel", this.scrollFun);
    } else {
      document.onmousewheel = this.scrollFun;
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll, true);
    // window.removeEventListener("scroll", this.changescroll, true);
    document.removeEventListener("mousewheel", this.scrollFun, false);
  },
};
</script>

<style lang="scss" scoped>
.xi {
  font-family: Alibaba PuHuiTi Xi !important;
}
.index-xinwen-lunbo {
  width: 100%;
  // height: 50px;
  position: absolute;
  left: 0;
  bottom: 30px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .lunbo {
    width: 1033px;
    height: 73px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    opacity: 1;
  }
}

.toback {
  position: fixed;
  top: 50%;
  right: 20px;
  z-index: 10;
  //display: none;
  .lianxi {
    width: 44px;
    height: 108px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    .ph {
      width: 32px;
      height: 32px;
      background: url("../../assets/imgs/home/toback-1.png");
      background-size: 100%;
      cursor: pointer;
      position: relative;
      .son {
        width: 140px;
        height: 200px;
        background-color: #fff;
        padding: 20px 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        top: -16px;
        left: -170px;
        //display: none;
        opacity: 0;
        .bar {
          width: 100%;
          // height: 30%;
          height: 22px;
          display: flex;
          flex-direction: column;
          //justify-content: space-between;
          justify-content: space-around;

          .top {
            font-size: 14px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;

            color: #333333;
            opacity: 1;
          }
          .bottom {
            font-size: 14px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;

            color: #4470ff;
            opacity: 1;
            margin-top: 4px;
          }
        }
      }
    }

    .wx {
      width: 32px;
      height: 32px;
      background: url("../../assets/imgs/home/toback (4).png");
      background-size: 100%;
      cursor: pointer;
      position: relative;
      .son {
        width: 110px;
        height: 140px;
        background-color: #fff;
        position: absolute;
        top: -56px;
        left: -140px;
        display: flex;
        flex-direction: column;
        padding: 20px 14px;
        display: none;
        img {
          width: 82px;
          height: 82px;
        }
        .tip {
          font-size: 12px;
          font-family: Alibaba PuHuiTi;
          font-weight: 300;

          color: #979797;
          opacity: 1;
        }
      }
    }
    .ph:hover {
      background: url("../../assets/imgs/home/toback (2).png");
    }
    .ph:hover .son {
      opacity: 1;
    }
    .wx:hover {
      background: url("../../assets/imgs/home/toback (3).png");
    }
    .wx:hover .son {
      display: block;
    }
  }

  .up {
    margin-top: 20px;
    width: 48px;
    height: 48px;
    background: linear-gradient(180deg, #4470ff 0%, #44b4ff 100%);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    cursor: pointer;
    background-image: url("../../assets/imgs/home/totop.png");
    background-size: 100% 100%;
  }
}

.index {
  // width: 100%;
  width: 1920px;
  height: 100%;
  background-color: black;

  .banner {
    width: 1920px;
    height: 100%;
    //margin: 0 auto;
    position: relative;

    .imgx {
      // width: 1920px;
      // height: 700px;
      width: 100%;
      height: 100%;
      img {
        // width: 1920px;
        // height: 700px;
        width: 100%;
        height: 100%;
      }
    }
    .bobao {
      width: 100%;
      position: absolute;
      bottom: 20px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;

      .item {
        width: 1030px;
        height: 74px;
        // background: rgba(0, 0, 0, 1);
        // filter: blur(22px);
        backdrop-filter: blur(22px);
        padding: 12px 50px;

        .box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          line-height: 16px;
          color: #ffffff;
          position: relative;

          .title {
            margin-left: 28px;
          }
          .time {
            margin-left: 150px;
          }
          .img {
            width: 126px;
            height: 47px;
            margin-left: 40px;
          }
        }
      }
      .change {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 18px;
        position: absolute;
        top: 0;
        left: 510px;
        .shang {
          cursor: pointer;
        }
        .xia {
          cursor: pointer;
        }
      }
    }
  }
  .introductory {
    width: 1920px;
    height: 124px;
    background: rgba(29, 29, 29, 1);
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    .son {
      width: 18%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      //  background-color: pink;
      .img {
        width: 62px;
        height: 72px;
        cursor: pointer;
        transition: all 0.3s linear;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .text {
        margin-left: 10px;
        .top {
          font-size: 18px;
          font-weight: 700;
        }
        .bottom {
          font-size: 14px;
          color: rgba(162, 162, 162, 1);
        }
      }
    }
    .son:hover .img {
      margin-bottom: 24px;
      // width: 20%;
    }
    .son:hover .top {
      color: rgba(68, 112, 255, 1);
    }
  }
}
</style>
