<template>
  <div class="icon">
    <div class="di" :class="dongxiao == 4 ? 'fade-in-bottom' : ''"></div>

    <div
      class="box"
      :class="[{ xiangshang: bool }, { 'fade-bottom-1': dongxiao == 4 }]"
    >
      <div class="yi"></div>
      <div class="er"></div>
      <div class="san"></div>

      <div class="center">
        <div class="fuwu">累计服务超过<span> 2000+ </span>家企业客户</div>
      </div>
    </div>
    <div
      class="bottom"
      :class="[{ xiangshang: bool }, { 'fade-bottom-1': dongxiao == 4 }]"
      v-if="ICount == 4"
    >
      <div class="son" v-for="(item, index) in list" :key="index">
        <img :src="item.src" alt="" class="img" />
        <div class="num">
          <!-- {{ item.num }} -->
          <ICountUp :endVal="item.num" :options="options" ref="xx" />
          <div class="danwei">{{ item.danwei }}</div>
        </div>

        <div class="text">{{ item.text }}</div>
      </div>
    </div>
    <!-- <div class="xx"></div> -->
    <div class="ft" :class="bool ? 'icon-bottom' : ''"><Footer></Footer></div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import Footer from "../../../components/Footer.vue";
export default {
  components: {
    ICountUp,
    Footer,
  },
  props: ["bool", "ICount", "dongxiao"],
  data() {
    return {
      list: [
        {
          src: require("../../../assets/imgs/home/bottomicon (2).png"),
          danwei: "+",
          num: 2000,
          text: "服务数量",
        },
        {
          src: require("../../../assets/imgs/home/bottomicon (3).png"),
          danwei: "年",
          num: 23,
          text: "公司成立",
        },
        {
          src: require("../../../assets/imgs/home/bottomicon (4).png"),
          danwei: "套",
          num: 200,
          text: "相关产品",
        },
        {
          src: require("../../../assets/imgs/home/bottomicon (1).png"),
          danwei: "%",
          num: 90,
          text: "分布区域",
        },
      ],
      options: {
        useEasing: true,
        useGrouping: true,
        separator: "", //分割 符号
        decimal: ".",
        prefix: "",
        suffix: "",
        duration: 2.5,
      },
    };
  },
  mounted() {
    // console.log(" this.$refs.xx :>> ", this.$refs.xx);
    // console.log("this.bool :>> ", this.bool);
  },
};
</script>

<style lang="scss" scoped>
// .xx {
//   width: 100%;
//   height: 2000px;
//   background-color: pink;
// }

// .xiangshang {
//   bottom: 500px;
//   transition: all 1s linear;
// }

.icon {
  width: 100%;
  height: 100%;

  background: url("../../../assets/imgs/home/btbg.png");
  background-size: 100% 100%;
  padding: 129px 0;

  // position: relative;
  // left: 0;
  // top: -500px;
  position: relative;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
  .di {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(21, 21, 21, 1);

    // display: none;
    z-index: -10;
  }
  .box {
    width: 100%;
    position: relative;
    padding: 0 260px;
    // display: none;
    //bottom: 500px;
    // transition: all 1s linear;
    margin-top: 100px;
    .yi {
      width: 100%;
      height: 60px;
      background: url("../../../assets/imgs/home/bottom (1).png");
      background-size: 100% 100%;
      margin-bottom: 68px;
    }
    .er {
      width: 100%;
      height: 60px;
      background: url("../../../assets/imgs/home/bottom (3).png");
      background-size: 100% 100%;
      margin-bottom: 68px;
    }
    .san {
      width: 100%;
      height: 60px;
      background: url("../../../assets/imgs/home/bottom (2).png");
      background-size: 100% 100%;
      margin-bottom: 68px;
    }
    .si {
      width: 100%;
      height: 60px;
      background: url("../../../assets/imgs/home/icon (8).png");
      background-size: 100% 100%;
      margin-bottom: 68px;
    }
    .center {
      width: 870px;
      // height: 132px;
      background-color: rgba(4, 4, 4, 1);

      position: absolute;
      left: 530px;
      top: -121px;

      display: flex;
      justify-content: center;
      align-items: center;

      .fuwu {
        font-size: 40px;
        font-weight: 700;
        span {
          color: rgba(68, 112, 255, 1);
        }
      }
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    padding: 0 260px;
    position: relative;
    // left: 0;
    // bottom: 500px;
    .son {
      width: 310px;
      height: 134px;
      background-color: rgb(22, 26, 34);
      border-radius: 10px;
      position: relative;
      .img {
        width: 84px;
        height: 84px;
        position: absolute;
        top: 20px;
        left: 20px;
      }
      .num {
        position: absolute;
        top: 20px;
        left: 120px;
        font-size: 60px;
        font-weight: 700;
        .danwei {
          font-size: 14px;
          position: absolute;
          right: -20px;
          top: 8px;
          font-family: Alibaba PuHuiTi Xi !important;
        }
      }
      .text {
        font-size: 16px;
        color: rgba(136, 136, 136, 1);
        position: absolute;
        font-family: Alibaba PuHuiTi Xi !important;
        left: 120px;
        bottom: 24px;
      }
    }
  }
  .ft {
    position: absolute;
    left: 0;
    bottom: -600px;
    // display: none;
  }
}
</style>
