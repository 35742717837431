<template>
  <div class="anli align">
    <h2 :class="dongxiao == 3 ? 'fade-bottom' : ''">
      典型案例
      <div class="jieshao">为经济社会持续发展提供信息化服务</div>
    </h2>
    <div class="anli-lunbo">
      <el-carousel
        indicator-position="none"
        arrow="never"
        @change="change"
        ref="carousel"
        :interval="3000"
      >
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div class="box" @mouseover="yiru(item)" @mouseout="yichu(item)">
            <div class="left" :class="dongxiao == 3 ? 'left-enter' : ''">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="text">
                {{
                  item.text.length > 194
                    ? item.text.slice(0, 191) + "..."
                    : item.text
                }}
              </div>
              <div class="line" ref="line"></div>
              <div class="gengduo" @click="gotomore(item)">
                了解更多
                <i class="el-icon-right"></i>
              </div>
            </div>
            <div class="right" :class="dongxiao == 3 ? 'right-enter' : ''">
              <img
                class="img"
                :src="item.imgs[0].src"
                alt=""
                @click="gotomore(item)"
              />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="qiehuan" :class="dongxiao == 3 ? 'right-enter' : ''">
        <div
          class="son"
          v-for="(item, index) in list"
          :key="index"
          :class="i == item.i ? 'active' : ''"
          @click="goto(item)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dongxiao"],
  data() {
    return {
      list: [
        {
          i: 0,
          src: require("../../../assets/imgs/home/yingxiao.png"),
          title: "成都市智慧水务六水数据信息平台",
          text: "以成都市城市大脑为基础，通过若干控制性重点水务信息化项目的建设整合和完善现有信息系统功能，全面接入以“六水”为代表的各业务功能打造全域感知的泛在感知系统，全量汇聚水务数据，强化数据挖掘在山洪预测、管网优化、水资源调度等领域的应用，实现全域感知（水质、水量等）、动态监测（各种水务设备和水利设施）、高效应用（多屏展示、GIS呈现等）的智慧水务平台。把具体业务系统分为水资源支撑模块、水安全保障模块、水净化提升模块、水生态修复模块、水管理创新模块及水文化传承模块，从而打造具有成都特色的业务系统。综合单位实际情况及工作的特殊性专门设计，使系统不仅在当前处于领先地位，而且能为今后的发展提供数据支撑。在功能设计、软件操作以及其它方面设身处地为用户着想，以用户为中心，设计以实用性为第一目的的系统。 采用面向用户的设计风格，设计满足人性化操作方式的展示系统。",
          imgs: [
            { src: require("../../../assets/imgs/anli/liushui (2).png") },
            { src: require("../../../assets/imgs/anli/liushui (1).png") },
            { src: require("../../../assets/imgs/anli/liushui (3).png") },
            { src: require("../../../assets/imgs/anli/liushui (4).png") },
            { src: require("../../../assets/imgs/anli/liushui (5).png") },
            { src: require("../../../assets/imgs/anli/liushui (6).png") },
            { src: require("../../../assets/imgs/anli/liushui (7).png") },
            { src: require("../../../assets/imgs/anli/liushui (8).png") },
            { src: require("../../../assets/imgs/anli/liushui (9).png") },
            { src: require("../../../assets/imgs/anli/liushui (10).png") },
          ],
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
        },

        {
          i: 1,
          src: require("../../../assets/imgs/home/yingxiao.png"),
          title: "成都市供水数据接入与实时监测系统",
          text: "经开水务利用新一代的GIS技术，构建高效、合理、实用的供水管网信息系统，为水司各项工作开展及后续信息化系统建设提供数据支撑，进而应用至巡检、抢维修等业务中，辅助各部分工作与管网数据进行紧密结合，从而实现更加精细化的管理，实现各类业务流程、分析结果、运营信息基于地图的可视化展现，辅助水司进行宏观决策、综合运营，实现城市供水管网智慧运作，最终实现龙泉驿自来水公司“智慧水务”的发展目标。",
          imgs: [
            { src: require("../../../assets/imgs/anli/gongshui-1.png") },
            { src: require("../../../assets/imgs/anli/gongshui-2.png") },
            { src: require("../../../assets/imgs/anli/gongshui-3.png") },
            { src: require("../../../assets/imgs/anli/gongshui-4.png") },
            { src: require("../../../assets/imgs/anli/gongshui-5.png") },
          ],
          kehu: "经开水务",
          city: "四川省成都市",
          type: 0,
        },
        {
          i: 2,
          src: require("../../../assets/imgs/home/yingxiao.png"),
          title: "成都市排水数据接入与实时监测系统",
          text: "建设内容由综合信息展示系统、业务应用系统、排水GIS服务平台和排水系统数据库建设等构成验。",
          imgs: [
            { src: require("../../../assets/imgs/anli/paishui (1).png") },
            { src: require("../../../assets/imgs/anli/paishui (2).png") },
            { src: require("../../../assets/imgs/anli/paishui (3).png") },
            { src: require("../../../assets/imgs/anli/paishui (4).png") },
            { src: require("../../../assets/imgs/anli/paishui (5).png") },
          ],
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
        },
        {
          i: 3,
          src: require("../../../assets/imgs/home/yingxiao.png"),
          title: "锦江绿道府河BIM信息系统",
          text: "以成都地形图为基础，通过3DBIM渲染技术数字还原整个26公里河道，让用户在线体验锦江绿道的真实魅力。同时也让管理者通过绿道的监测信息，实时监测河道点位状态，做好及时防范。同时，在系统上叠加相关河段的巡查信息，便于直观掌握26公里绿道日常运维情况。",
          imgs: [
            { src: require("../../../assets/imgs/anli/bim (1).png") },
            { src: require("../../../assets/imgs/anli/bim (2).png") },
            { src: require("../../../assets/imgs/anli/bim (3).png") },
            { src: require("../../../assets/imgs/anli/bim (4).png") },
            { src: require("../../../assets/imgs/anli/bim (5).png") },
            { src: require("../../../assets/imgs/anli/bim (6).png") },
            { src: require("../../../assets/imgs/anli/bim (7).png") },
            { src: require("../../../assets/imgs/anli/bim (8).png") },
            { src: require("../../../assets/imgs/anli/bim (9).png") },
            { src: require("../../../assets/imgs/anli/bim (10).png") },
            { src: require("../../../assets/imgs/anli/bim (11).png") },
            { src: require("../../../assets/imgs/anli/bim (12).png") },
            { src: require("../../../assets/imgs/anli/bim (13).png") },
            { src: require("../../../assets/imgs/anli/bim (14).png") },
          ],
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
        },
      ],
      i: 0,
      line: false,
    };
  },
  methods: {
    change(val) {
      this.i = val;
    },
    goto(item) {
      this.$refs.carousel.setActiveItem(item.i);
    },
    yiru(val) {
      this.$refs.line[val.i].style.width = "100%";
    },
    yichu(val) {
      this.$refs.line[val.i].style.width = "0%";
    },
    gotomore(item) {
      this.$router.push({ name: "xiangqing", params: item });
    },
  },
};
</script>

<style lang="scss" scoped>
.anli {
  width: 1920px;
  height: 100%;
  background: url("../../../assets//imgs//home/anlibg.png");
  background-size: 100% 100%;
  padding-top: 104px;
  .anli-lunbo {
    width: 100%;
    height: 370px;
    margin-top: 138px;
    position: relative;
    .box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .left {
        width: 590px;
        height: 100%;

        position: relative;
        .title {
          font-size: 28px;
          font-family: Alibaba PuHuiTi;
          font-weight: bold;
          line-height: 48px;
          color: #ffffff;
        }
        .text {
          font-size: 18px;
          font-family: Alibaba PuHuiTi Xi !important;
          font-weight: 400;
          line-height: 26px;
          color: #c4c4c4;
          margin-top: 60px;
        }
        .line {
          position: absolute;
          width: 0%;
          height: 1px;
          background-color: #ffffff;
          left: 0;
          top: 276px;
          transition: all 1.2s cubic-bezier(0.39, 0.575, 0.565, 1);
        }
        .gengduo {
          position: absolute;
          bottom: 0;
          left: 2px;
          width: 128px;
          height: 44px;
          border: 1px solid #ffffff;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-family: Alibaba PuHuiTi Xi !important;
          cursor: pointer;
          opacity: 0.4;
        }
        .gengduo:hover {
          opacity: 1;
        }
      }
      .right {
        width: 664px;
        height: 100%;
        position: relative;
        overflow: hidden;
        .img {
          width: 100%;
          height: 100%;
          transition: all 0.6s linear;
        }
      }
    }

    .box:hover .right .img {
      transform: scale(1.1, 1.1);
    }
    .qiehuan {
      position: absolute;
      right: 0;
      bottom: -40px;
      display: flex;
      .son {
        width: 20px;
        height: 4px;
        background: rgba(255, 255, 255, 0.39);
        border-radius: 55px;
        margin-left: 4px;
        cursor: pointer;
      }
      .active {
        width: 40px;
        height: 4px;
        background: rgba(255, 255, 255, 1);
        border-radius: 55px;
      }
    }
  }
}

.biankuan {
  animation: kuan 1.5s linear both;
}

@keyframes kuan {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>